<template>
  <b-form @submit.prevent="saveData" ref="form">
    <b-row class="mb-2">
      <b-col lg="12" md="12" sm="12">
        <div role="group">
          <label for="name" class="label_input">Sede</label>
          <b-form-input v-model="nameBrand" id="name" size="sm" disabled />
        </div>
      </b-col>
      <!-- <b-col lg="6" md="6" sm="12">
        <div role="group">
          <label class="label_input">Servicio</label>
          <v-select
            class="style-chooser"
            v-model="formData.selectedService"
            label="text"
            :options="services"
            :reduce="(option) => option.id"
            placeholder="Seleccione servicio"
            @input="loadAreaByService"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="12" md="12" sm="12">
        <div role="group">
          <label class="label_input">Área</label>
          <v-select
            class="style-chooser"
            v-model="formData.selectedArea"
            label="text"
            :options="areas"
            placeholder="Seleccione area"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col> -->
      <b-col lg="12" md="12" sm="12">
        <div role="group">
          <label class="label_input">Dispositivos</label>
          <v-select
            v-model="formData.selectDevice"
            :options="devices"
            label="name"
            class="style-chooser"
            placeholder="Seleccione"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="d-flex justify-content-end mt-2">
        <b-button
          type="submit"
          class="btn btn-success"
          size="sm"
          :disabled="isBusy || $v.$invalid"
        >
          <b-spinner v-if="isBusy" small />
          Agregar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import { v4 as uuidv4 } from 'uuid';
import { mapState, mapActions } from "vuex";

export default {
  inject: ["deviceRepository"],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BSpinner,
    BFormInput,
    vSelect,
  },
  props: {
    brandId: {
      type: String,
      default: null,
    },
    nameBrand: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isBusy: false,
      services: [],
      areas: [],
      devices: [],
      formData: {
        selectDevice: null,
      },
    };
  },
  validations: {
    formData: {
      selectDevice: {
        required,
      },
    },
  },
  computed: {
    ...mapState("device", ["layout"]),
  },
  async mounted() {
    const me = this;
    me.loadDevices();
  },
  methods: {
    ...mapActions("device", ["addDevice"]),
    async saveData() {
      const me = this;
      const { sensor, type, mac, id } = me.formData.selectDevice;

      const data = {
        id,
        sensor,
        type,
        mac,
        // area: me.formData.selectedArea.text,
        img: me.addImgSensor(type),
        primarySensor: null,
        sensorBateria: null,
        sensorEnergia: null,
        timestamp: null,
      };
      if (data.type == "16") me.$emit("addTablePatient");
      me.addDevice(data)
      

      me.$bvModal.hide("modal-device");
    },
    addImgSensor(type) {
      let img = null;

      if (type == 13) {
        img = "temperatura1.png";
      } else if (type == 1) {
        img = "temperaturahumedad.png";
      } else if (type == 3) {
        img = "corriente1.png";
      } else if (type == 4) {
        img = "presion1.png";
      } else if (type == 2) {
        img = "giroscopio1.png";
      } else if (type == 15) {
        img = "vibracion1.png";
      } else {
        img = "default.png";
      }

      return img;
    },
    async loadDevices() {
      const me = this;
      try {
        const { data } = await me.deviceRepository.getAll(me.brandId);
        const devi = [
          {
            id: uuidv4(),
            sensor: 'Boton Multifuncional',
            name: 'Boton Multifuncional',
            type: "16",
          },
          {
            id: uuidv4(),
            sensor: 'Interruptor',
            name: 'Interruptor',
            type: "30",
          },
          {
            id: uuidv4(),
            sensor: 'Interruptor de luz',
            name: 'Interruptor de luz',
            type: "31",
          },
          {
            id: uuidv4(),
            sensor: 'Apertura de puerta',
            name: 'Apertura de puerta',
            type: "32",
          }
        ]
        me.devices = devi;
        
        data.forEach(el => {
          if (el.device_type !== "16") {
            me.devices.push({
              id: el.id,
              mac: el.mac,
              sensor: el.device_type_name,
              name: el.subarea_name + " - " + el.location_name + " - " + el.device_type_name + ' - ' + el.mac,
              type: el.device_type,
            });
          }
        });

        if (me.layout.length > 0) {

          me.layout.forEach(x => {
            const index = me.devices.findIndex(el => el.mac == x.device.mac);
            me.devices.splice(index, 1);
          });

        }
      } catch (error) {
        // console.log(error);
      }
    }
  },
};
</script>

<style></style>
