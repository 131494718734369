<template>
  <div class="time_chronometer">
    {{ minutes }} : {{ seconds }}
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ['timestamp', 'mac'],
  data() {
    return {
      seconds : 0,
      minutes: 0,
      interval: null,
    }
  },
  watch: {
    timestamp(newTime) {
      const me = this;
      me.calculateElapsedMinutes(newTime);
    }
  },
  methods: {
    chronometer() {
      const me = this;
      me.interval = setInterval(() => {
        me.counter();
      }, 1000);
    },
    counter() {
      const me = this;
      me.seconds++;

      if (me.seconds < 10) me.seconds = `0${me.seconds}`;
      if (me.seconds == 60) {
        me.minutes++;
        me.seconds = 0;

        if (me.minutes < 10) me.minutes = `0${me.minutes}`;

        if (me.minutes == 5) {
          me.minutes = 0;
          me.seconds = `0${me.seconds}`;
          clearInterval(me.interval);
          me.$emit("deleteCall", me.mac)
        }
      }
    },
    calculateElapsedMinutes(timestamp) {
      const me = this;
      clearInterval(me.interval);
      me.seconds  = 0;
      me.minutes = 0;

      const timeHour = timestamp.split(" ")
      const minuteSecond = timeHour[1].split(":");
      const time = `${minuteSecond[1]}:${minuteSecond[2]}`;

      const currentHour = moment().format('mm:ss');

      const diff = moment(currentHour,"mm:ss").diff(moment(time,"mm:ss"));
      const duration = moment.utc(diff).format("mm:ss");

      const minutes = duration.split(":")[0];
      const seconds = duration.split(":")[1];

      me.minutes = minutes;
      me.seconds = seconds;
      me.chronometer();
    }
  },
  mounted() {
    const me = this;
    me.calculateElapsedMinutes(me.timestamp);
  }
}
</script>

<style>

</style>