<template>
  <div>
    <div v-if="item.type === '16'">
      <div class="d-flex justify-content-between align-items-center">
        <div class="title_sensor24">
          {{ item.sensor }} - Area: {{ nameArea }}
        </div>
        <div
          v-if="isEdit"
          class="remove"
          :id="index"
          @click.prevent="remove(index)"
        >
          <b-icon-x />
        </div>
      </div>
      <call-component
        v-if="item.calls.length > 0"
        :calls="item.calls"
        @deleteCall="deleteCall"
      />
      <list-patient
        :patients="patients"
        :isBusy="isBusy"
        @reloadDiets="$emit('reloadDiets')"
        :is-busy2="isBusy2"
        :is-error="isError"
        :is-empty="isEmpty"
        v-else
      />
    </div>
    <card-sensor
      v-else
      :item="item"
      :index="index"
      :isEdit="isEdit"
      :nameArea="nameArea"
      :nameEnterprise="nameEnterprise"
      @setting="setting"
      @loadDataDevices="() => $emit('loadDataDevices')"
    />
  </div>
</template>

<script>
import { VBTooltip, BIconX } from "bootstrap-vue";
import ListPatient from "./ListPatient";
import CardSensor from "./CardSensor";
import CallComponent from "./CallComponent";
import { mapActions } from "vuex";

export default {
  components: {
    BIconX,
    ListPatient,
    CardSensor,
    CallComponent,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    nameArea: {
      type: String,
      defaul: null,
    },
    nameEnterprise: {
      type: String,
      defaul: null,
    },
    patients: {
      type: Array,
      default: null,
    },
    isBusy: {
      type: Boolean,
      default: false,
    },
    isBusy2: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      max: 100,
      value: 40,
    };
  },
  methods: {
    ...mapActions("device", ["removeDevice"]),
    remove(index) {
      // this.$emit("removeDevice", index);
      const me = this;
      me.removeDevice(index);
    },
    // removeDevice(index) {
    //   this.$emit("removeDevice", index);
    // },
    deleteCall(mac, sensor) {
      this.$emit("deleteCall", mac, sensor);
    },
    setting(item) {
      this.$emit("setting", item);
    },
  },
};
</script>

<style lang="css">
.card_sensor {
  border: none;
  padding: 3px;
  padding-bottom: 0px;
}

.smartwidget {
  border: 1px solid transparent;
  border-radius: 6px;
  background: #fff;
  z-index: 0;
  overflow: hidden;
  padding: 5px;
}

.smartwidget:hover {
  border: 1px solid rgba(41, 162, 204, 1);
}

.header__10 {
  position: relative;
  top: -5px;
}

.title_sensor {
  font-size: 18px;
  color: var(--black-color);
  margin-bottom: 0px;
  width: 160px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.title_sensor24 {
  font-size: 12px;
  color: var(--black-color);
  margin-bottom: 0px;
  margin-left: 10px;
}

.subtitle_sensor {
  font-size: 12px;
  color: var(--black-color);
  width: 160px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.value_sensor {
  font-size: 18px;
  color: var(--black-color);
  font-weight: bold;
}

.icono_sensor {
  height: 50px;
}

.body__10 {
  position: relative;
  top: -5px;
  height: 66px;
}
/* .footer__10 {
  position: relative;
  top: 5px;
} */

.btn_action {
  cursor: pointer;
  color: var(--black-color) !important;
  font-size: 20px;
}

.bs-tooltip-top {
  font-size: 12px;
}

.remove {
  cursor: pointer;
}

.icono_info {
  font-size: 18px;
  cursor: pointer;
  color: #7367f0;
}

.img_indicador {
  width: 26px;
}

.img_action1 {
  width: 22px;
}

.img_action2 {
  width: 28px;
}

.text_bateria {
  font-size: 14px;
  color: var(--black-color);
  font-weight: bold;
  margin-left: 5px;
}

.line__ {
  padding: 0.1px;
  background: rgb(230, 228, 228);
}
</style>
