<template>
  <div class="">
    <b-row>
      <b-col lg="4" md="4" sm="12" class="mb-3">
        <b-button class="w-100 mr-3" variant="info" @click.prevent="isName ? isName = false : isName = true">
          Nombre del sensor
        </b-button>
        <form-name v-if="isName" :nameDevice="sensor.sensor" @save="save" />
      </b-col>
      <b-col lg="3" md="3" sm="12" class="mb-3">
        <b-button class="w-100 mr-3" variant="info" @click.prevent="isTime ? isTime = false : isTime = true"> Tiempo </b-button>
        <form-time v-if="isTime" @save="save" />
      </b-col>
      <b-col lg="5" md="5" sm="12" class="mb-3">
        <b-button class="w-100" variant="info" @click.prevent="isLevel ? isLevel = false : isLevel = true"> Niveles de alarma </b-button>
        <form-level :sensors="sensors" v-if="isLevel" @save="save" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BButton, BCol, BRow } from "bootstrap-vue";
import FormName from "./formSetting/FormName";
import FormTime from "./formSetting/FormTime";
import FormLevel from "./formSetting/FormLevel";

export default {
  props: {
    sensor: {
      type: Object,
      default: null,
    },
    sensors: {
      type: Array,
      default: () => [], 
    },
  },
  components: {
    BButton,
    BCol, 
    BRow,
    FormName,
    FormTime,
    FormLevel,
  },
  data() {
    return {
      isName: true,
      isTime: false,
      isLevel: false,
    }
  },
  methods: {
    save(type, formData) {
      const me = this;
      me.$emit('saveSetting', type, { ...formData, mac: me.sensor.mac});
    }
  },
};
</script>

<style lang="css">
  .btn_time {
    padding: 0;
    height: 35px;
    width: 35px;
    border-radius: 50%;
  }
</style>
