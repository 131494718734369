<template>
  <div>
    <div class="card_sensor">
      <content-sensor
        :item="item"
        :index="index"
        :isEdit="isEdit"
        :nameArea="nameArea"
        :nameEnterprise="nameEnterprise"
        @setting="() => $emit('setting', item)"
        @loadDataDevices="() => $emit('loadDataDevices')"
      />
    </div>
  </div>
</template>

<script>
import ContentSensor from "./ContentSensor";

export default {
  components: {
    ContentSensor,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    nameArea: {
      type: String,
      defaul: null,
    },
    nameEnterprise: {
      type: String,
      defaul: null,
    },
  },
  data() {
    return {
      max: 100,
      value: 40,
      value2: 0,
    };
  },
};
</script>

<style lang="css">
.card_sensor {
  border: none;
  padding: 3px;
  padding-bottom: 0px;
}

.smartwidget {
  border: 1px solid transparent;
  border-radius: 6px;
  background: #fff;
  z-index: 0;
  overflow: hidden;
  padding: 5px;
}

.smartwidget:hover {
  border: 1px solid rgba(41, 162, 204, 1);
}

.header__10 {
  position: relative;
  top: -5px;
}

.title_sensor {
  font-size: 18px;
  color: var(--black-color);
  margin-bottom: 0px;
  width: 160px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.title_sensor24 {
  font-size: 12px;
  color: var(--black-color);
  margin-bottom: 0px;
  margin-left: 10px;
}

.subtitle_sensor {
  font-size: 12px;
  color: var(--black-color);
  width: 160px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.value_sensor {
  font-size: 18px;
  color: var(--black-color);
  font-weight: bold;
}

.icono_sensor {
  height: 50px;
}

.body__10 {
  position: relative;
  top: -5px;
  height: 66px;
}
/* .footer__10 {
  position: relative;
  top: 5px;
} */

.btn_action {
  cursor: pointer;
  color: var(--black-color) !important;
  font-size: 20px;
}

.bs-tooltip-top {
  font-size: 12px;
}

.remove {
  cursor: pointer;
}

.icono_info {
  font-size: 18px;
  cursor: pointer;
  color: #7367f0;
}

.btn-oclock {
  font-size: 18px;
  cursor: pointer;
  color: #7367f0 !important;
  cursor: pointer;
}

.img_indicador {
  width: 26px;
}

.img_action1 {
  width: 22px;
}

.img_action2 {
  width: 28px;
}

.text_bateria {
  font-size: 14px;
  color: var(--black-color);
  font-weight: bold;
  margin-left: 5px;
}

.line__ {
  padding: 0.1px;
  background: rgb(230, 228, 228);
}

.custom-range {
  border: 1px solid #7367f0;
  border-radius: .3rem;
  padding: 5px;
}

input[type=range]::-webkit-slider-runnable-track{
  background-color: #7367f0;
}

input[type=range]::-moz-range-track{
  background-color: #7367f0;
}

input[type=range]::-ms-track{
  background-color: #7367f0;
}

input[type=range]::-webkit-slider-thumb{
  background-color: #7367f0;
  border: 4px solid #b2b2b2;
}

input[type=range]::-moz-range-thumb {
  background-color: #7367f0;
  border: 4px solid #b2b2b2;
}

input[type=range]::-ms-thumb {
  background-color: #7367f0;
  border: 4px solid #b2b2b2;
}
</style>
