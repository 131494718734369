<template>
  <div>
    <Header :title="title">
      <template v-slot:btn-actions>
        <b-button
          variant="outline-secondary btn_header"
          class="margin-right"
          @click="newDevice"
        >
          <b-icon-plus-circle />
        </b-button>
        <b-button
          v-if="isEdit"
          variant="success btn_header"
          class="margin-right"
          @click="saveDash"
        >
          Guardar
        </b-button>
        <b-button
          v-else
          variant="outline-secondary btn_header"
          class="margin-right"
          @click="editDash"
        >
          <b-icon-pencil-square />
        </b-button>
      </template>
    </Header>
    <div class="pl-1 pr-1 mt-1">
      <div class="grid-widget">
        <smart-widget-grid
          :layout.sync="layout"
          :col-num="colNum"
          :isDraggable="layout.isDraggable"
          :isResizable="layout.isResizable"
        >
          <smart-widget
            simple
            v-for="(item, index) in layout"
            :key="index"
            :slot="item.i"
            :i="item.i"
          >
            <list-device
              :item="item.device"
              :index="index"
              :isEdit="isEdit"
              :nameArea="nameArea"
              :nameEnterprise="nameEnterprise"
              :patients="patients"
              :isBusy="isBusy"
              :is-busy2="isBusy2"
              :is-error="isError"
              :is-empty="isEmpty"
              @deleteCall="deleteCall"
              @reloadDiets="loadOrdersCurrentDay"
              @loadDataDevices="loadDataDevices"
              @setting="setting"
            />
          </smart-widget>
        </smart-widget-grid>
      </div>
    </div>
    <modal-base title="Agregar dispositivos" :id="id">
      <template v-slot:form>
        <form-device
          :brandId="brandId"
          :nameBrand="title"
          @addTablePatient="loadLocationPatient"
        />
      </template>
    </modal-base>
    <modal-base title="Configuración de dispositivo" :id="idModalSetting">
      <template v-slot:form>
        <form-setting
          :sensor="sensor"
          :sensors="sensors"
          @saveSetting="saveSetting"
        />
      </template>
    </modal-base>
  </div>
</template>

<script>
import { optionsMqtt } from "@/config";
import mqtt from "mqtt";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { mapState, mapActions } from "vuex";
moment.locale("es");
//Components
import { BIconPlusCircle, BIconPencilSquare, BButton } from "bootstrap-vue";
import Header from "@/components/header/Header";
import ModalBase from "@/components/modal/ModalBase";
import FormDevice from "./components/FormDevice";
import ListDevice from "./components/ListDevice";
import FormSetting from "./components/FormSetting";

export default {
  inject: ["patientBedRepository", "clientRepository"],
  components: {
    Header,
    BButton,
    BIconPlusCircle,
    BIconPencilSquare,
    ModalBase,
    FormDevice,
    ListDevice,
    FormSetting,
  },
  data() {
    const me = this;
    return {
      title: "",
      nameArea: "",
      nameEnterprise: null,
      id: "modal-device",
      idModalSetting: "setting-device",
      brand: me.$route.params.sede,
      area: me.$route.params.area,
      brandId: me.$route.params.idBrand,
      areaId: me.$route.params.id,
      topic: null,
      client: null,
      sensor: null,
      patients: [],
      sensors: [],
      isBusy: false,
      isBusy2: true,
      isError: false,
      isEmpty: false,
      current_day: (moment(), moment()).format("YYYY-MM-DD"),
    };
  },
  computed: {
    ...mapState("device", ["layout", "colNum", "isEdit"]),
    ...mapState("user", ["loggedUser"])
  },
  methods: {
    ...mapActions("device", ["loadDevices", "calls", "editDash", "saveDash"]),
    newDevice() {
      const me = this;
      me.$bvModal.show(me.id);
    },
    startMqttClient() {
      const me = this;

      me.topic = `${me.brand}/+/#`;

      const connectUrl =
        "wss://" +
        optionsMqtt.host +
        ":" +
        optionsMqtt.port +
        optionsMqtt.endpoint;

      try {
        const uuid = uuidv4();
        me.client = mqtt.connect(connectUrl, {
          ...optionsMqtt,
          clientId: uuid,
        });
      } catch (error) {
        console.error(error);
      }

      me.client.on("connect", () => {
        console.log("Connection succeeded");
        me.client.subscribe(me.topic, { qos: 0 }, (err) => {
          if (err) {
            console.log("Error in subscription", err);
            return;
          }

          console.log("Subscription success");
          me.loadDataDevices();
        });
      });

      me.client.on("error", (error) => {
        console.log("Connection failed", error);
      });

      me.client.on("reconnect", (error) => {
        console.log("Reconnecting", error);
      });

      me.client.on("message", (topic, message) => {
        try {
          const messageReceived = JSON.parse(message.toString());
          if (messageReceived.llamado && messageReceived.llamado !== "Alta") {
            me.patients.forEach((el) => {
              if (el.location_patient) {
                el.devices.forEach((x) => {
                  if (
                    x.device_type_id ==
                      "a92f1b6a-9067-48d8-a00e-66802b0a2af7" &&
                    x.mac == messageReceived.mac
                  ) {
                    me.calls({
                      ...messageReceived,
                      areaLoca: me.area,
                      patient: el,
                    });
                  } else if (
                    x.device_type_id ==
                      "2224c6e8-51a4-4bb1-8ac1-7bf5bdee1932" &&
                    x.mac == messageReceived.mac
                  ) {
                    me.calls({
                      ...messageReceived,
                      areaLoca: me.area,
                      patient: el,
                    });
                  } else if (
                    x.device_type_id ==
                      "e5d8d6a7-3b5a-44fb-bd92-2e0c93995f72" &&
                    x.mac == messageReceived.mac
                  ) {
                    me.calls({
                      ...messageReceived,
                      areaLoca: me.area,
                      patient: el,
                    });
                  }
                });
              }
            });
          } else if (
            messageReceived.llamado &&
            messageReceived.llamado == "Alta"
          ) {
            me.patients.forEach((el) => {
              if (el.location_patient) {
                el.devices.forEach((x) => {
                  if (
                    x.device_type_id ==
                      "a92f1b6a-9067-48d8-a00e-66802b0a2af7" &&
                    x.mac == messageReceived.mac
                  ) {
                    if (el.isVisible) {
                      el.isVisible = false;
                      me.altaFisico(el.location_patient.id);
                    } else {
                      el.isVisible = true;
                      el.variant = "warning";
                      me.altaInfo(el.location_patient.id);
                    }
                  }
                });
              }
            });
          } else {
            me.patients.forEach(el => {
              el.devices.forEach(x => {
                if (
                  x.device_type_id == '9ff27a35-7d3c-4b18-a810-4cd36173184e' &&
                  x.mac == messageReceived.mac
                ) {
                  const item = el
                  item.device = messageReceived.sensor[0].values[0].value
                }
              })
            });
            me.calls(messageReceived);
          }
        } catch (error) {
          // console.log(error);
        }
      });
    },
    deleteCall(mac, sensor) {
      const me = this;

      const date = new Date();
      const timestamp = moment(date).format("YYYY-MM-DD HH:mm:ss");

      const payload = {
        llamado: "Cancelar",
        empresa: me.brand,
        sede: me.brand,
        area: me.area,
        mac: mac,
        timestamp: timestamp,
        sensor: sensor,
      };

      const payload2 = JSON.stringify(payload);
      const topic = `${me.brand}/${me.area}`;

      me.client.publish(topic, payload2, { qos: 0 }, (error) => {
        if (error) {
          console.log("Publish error", error);
        }
      });
    },
    sendMessage() {
      const me = this;
      const date = new Date();
      const timestamp = moment(date).format("MM-DD-YYYY HH:mm:ss");

      const payload = {
        area: me.area,
        empresa: me.brand,
        llamado: [{ name: "Cancelar" }],
        mac: "34:AB:95:8F:99:04",
        sede: me.brand,
        timestamp: timestamp,
      };

      const json = JSON.stringify(payload);

      const topic = "Inv_Castanez/Hosp_Norte";

      me.client.publish(topic, json, { qos: 0 }, (error) => {
        if (error) {
          console.log("Publish error", error);
        }
      });
    },
    async loadClient() {
      const me = this;
      try {
        const res = await me.clientRepository.find(me.loggedUser.customer_id);
        me.nameEnterprise = res.name;
      } catch (error) {
        // console.log(error);
      }
    },
    async loadLocationPatient() {
      const me = this;
      me.isBusy = true;
      me.patients = [];
      try {
        const res = await me.patientBedRepository.getAllLocationPatient(
          me.areaId
        );
        res.forEach((el) => {
          el.list.forEach((elem) => {
            let isVisible = false;
            let variant = null;
            if (elem.patient) {
              if (elem.location_patient.state == "2") {
                isVisible = true;
                variant = "warning";
              }
              me.patients.push({
                ...elem,
                area_id: el.area_id,
                orders: [],
                exams: [],
                examsTotal: [],
                isVisible: isVisible,
                variant: variant,
                device: null
              });
            }
          });
        });
      } catch (error) {
        me.patients = [];
        // console.log(error);
      } finally {
        me.isBusy = false;
      }
      if (me.patients.length > 0) {
        me.loadExamByAreaDate();
        me.loadOrdersCurrentDay();
      }
    },
    async loadOrdersCurrentDay() {
      const me = this;
      let orderPatients = null;
      try {
        me.isBusy2 = true;
        me.isError = false;
        me.isEmpty = false;
        const { data } = await me.patientBedRepository.getAllByDate(
          me.areaId,
          me.current_day
        );
        orderPatients = data;
        if (orderPatients.length > 0) {
          orderPatients.forEach((el) => {
            me.patients.forEach((x) => {
              if (x.patient && el.patient_id == x.patient.id) {
                x.orders.push({
                  id: el.id,
                  type: el.menu.type_menu,
                  status: !!el.arrival_at,
                });
              }
            });
          });
        }

        me.patients.forEach((el) => {
          el.orders.sort((a, b) => {
            if (a.type > b.type) {
              return 1;
            }
            if (a.type < b.type) {
              return -1;
            }
            return 0;
          });
        });
      } catch (error) {
        // const code = error.response.status
        me.isEmpty = true;
      } finally {
        me.isBusy2 = false;
      }
    },
    async loadExamByAreaDate() {
      const me = this;
      let examPatients = null;
      try {
        const { data } = await me.patientBedRepository.getAllExamByAreaDate(
          me.areaId,
          me.current_day
        );

        examPatients = data;
        if (examPatients.length > 0) {
          examPatients.forEach((el) => {
            me.patients.forEach((x) => {
              if (x.patient && el.patient_id == x.patient.id) {
                x.examsTotal.push(el);
                if (el.made_date) {
                  x.exams.push(el);
                }
              }
            });
          });
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async loadLocationPatientRefresh() {
      const me = this;
      me.patients = [];
      try {
        const res = await me.patientBedRepository.getAllLocationPatient(
          me.areaId
        );
        res.forEach((el) => {
          el.list.forEach((elem) => {
            let isVisible = false;
            let variant = null;
            if (elem.patient) {
              if (elem.location_patient.state == "2") {
                isVisible = true;
                variant = "warning";
              }
              me.patients.push({
                ...elem,
                area_id: el.area_id,
                orders: [],
                exams: [],
                examsTotal: [],
                isVisible: isVisible,
                variant: variant,
                device: null
              });
            }
          });
        });
      } catch (error) {
        me.patients = [];
        // console.log(error);
      }
      if (me.patients.length > 0) {
        me.loadExamByAreaDate();
        me.loadOrdersCurrentDay();
      }
    },
    async altaInfo(id) {
      const me = this;
      try {
        await me.patientBedRepository.altaInfo(id);
        me.loadLocationPatientRefresh();
      } catch (error) {
        // console.log(error);
      }
    },
    async altaFisico(id) {
      const me = this;
      try {
        await me.patientBedRepository.altaFisico(id);
        me.loadLocationPatientRefresh();
      } catch (error) {
        // console.log(error);
      }
    },
    fillDiet(order) {
      const me = this;
      me.isEmpty = false;
      me.patients.forEach((el) => {
        if (el.patient.id == order.patient_id) {
          el.orders.push({
            type: order.menu.type_menu,
            status: !!order.arrival_at,
          });
        }
      });
    },
    fillExam(exam) {
      const me = this;
      me.patients.forEach((el) => {
        if (el.patient.id == exam.patient_id) {
          el.examsTotal.push(exam);
          if (el.made_date) {
            el.exams.push(exam);
          }
        }
      });
    },
    fillAltaInfo(location) {
      const me = this;
      me.patients.forEach((el) => {
        if (el.patient.id == location.patient_id) {
          el.isVisible = true;
        }
      });
    },
    fillCancelAltaInfo(location) {
      const me = this;
      me.patients.forEach((el) => {
        if (el.patient.id == location.patient_id) {
          el.isVisible = false;
        }
      });
    },
    fillAlta(location) {
      const me = this;
      me.patients.forEach((el, index) => {
        if (el.id == location.location_id) {
          me.patients.splice(index, 1);
        }
      });
    },
    loadDataDevices() {
      const me = this;
      const topic = `${me.brand}/${me.area}`;
      const payload = `+peticion:TR`;

      me.client.publish(topic, payload, { qos: 0 }, (error) => {
        if (error) {
          console.log("Publish error", error);
        }
      });
    },
    setting(item) {
      const me = this;
      me.sensors = [];
      me.sensors.push(item.primarySensor);
      me.sensors.forEach(el => {
        el.values.forEach((x, index) => {
          if (x.type == "ANGULO Z") el.values.splice(index, 1);
        });
      });
      me.sensor = item;
      me.$bvModal.show(me.idModalSetting);
    },
    saveSetting(type, formData) {
      const me = this;
      const topic = `${me.brand}/${me.area}/${formData.mac}/Down`;
      let payload = null;

      if (type == "1") {
        payload = `+name-card:${formData.nameDevice}`;
      } else if (type == "2") {
        payload = `+config-sen:${formData.time}`;
      } else {
        if (formData.type == 1) {
          //Temperatura y humedad
          let temperatura = null;
          let humedad = null;

          formData.values.forEach((el) => {
            if (el.type == "TEMPERATURA") {
              temperatura = el;
            } else {
              humedad = el;
            }
          });

          payload = `1TH1:${temperatura.Lmin}<${temperatura.Lmax},${humedad.Lmin}<${humedad.Lmax}`;
        } else if (formData.type == 2) {
          //Giroscopio
          let angulox,
            anguloy,
            fv = null;

          formData.values.forEach((el) => {
            if (el.type == "ANGULO X") {
              angulox = el;
            } else if (el.type == "ANGULO Y") {
              anguloy = el;
            } else {
              fv = el;
            }
          });

          payload = `2IMU1:${angulox.Lmin}<${angulox.Lmax},${anguloy.Lmin}<${anguloy.Lmax},${fv.Lmin}<${fv.Lmax}`;
        } else if (formData.type == 3) {
          //Corriente
          let current,
            voltage = null;

          formData.values.forEach((el) => {
            if (el.type == "CURRENT") {
              current = el;
            } else {
              voltage = el;
            }
          });

          payload = `3CV1:${current.Lmin}<${current.Lmax},${voltage.Lmin}<${voltage.Lmax}`;
        } else if (formData.type == 4) {
          //Presión
          let presion = null;

          formData.values.forEach((el) => {
            presion = el;
          });

          payload = `4P1:${presion.Lmin}<${presion.Lmax}`;
        } else if (formData.type == 5) {
          //Calidad de aire
          let air = null;
          formData.values.forEach((el) => {
            air = el;
          });

          payload = `5A1:${air.Lmin}<${air.Lmax}`;
        } else if (formData.type == 6) {
          //Sensor de humo y gas
          let incendio = null;

          formData.values.forEach((el) => {
            incendio = el;
          });

          payload = `6INC1:${incendio.Lmin}<${incendio.Lmax}`;

        } else if (formData.type == 12) {
          //GPS
          let latitud,
            longitud,
            altitud = null;

          formData.values.forEach((el) => {
            if (el.type == "LATITUD") {
              latitud = el;
            } else if (el.type == "LONGITUD") {
              longitud = el;
            } else if (el.type == "ALTITUD") {
              altitud = el;
            }
          });

          payload = `12GPS1:${latitud.Lmin}<${latitud.Lmax},${longitud.Lmin}<${longitud.Lmax},${altitud.Lmin}<${altitud.Lmax}`;
        } else if (formData.type == 13) {
          //Temperatura
          let temperatura = null;

          formData.values.forEach((el) => {
            temperatura = el;
          });

          payload = `13T1:${temperatura.Lmin}<${temperatura.Lmax}`;
        }
      }

      me.client.publish(topic, payload, { qos: 0 }, (error) => {
        if (error) {
          console.log("Publish error", error);
        }
      });
      //Add sweetalert
      me.alertMessage("Actualizando configuración", "Se ha actualizado con exito.", "success");
      me.loadDataDevices();
    },
    alertMessage(title, text, icon) {
      this.$swal.fire({
        icon: icon,
        title: title,
        text: text,
      });
    },
  },
  created() {
    const me = this;
    me.loadClient();
    window.Echo.channel(`menu-order-${me.areaId}`)
      .listen(
        ".menu.order.created",
        (e) => {
          me.loadLocationPatientRefresh();
        }
      ).listen(
        ".menu.order.status",
        (e) => {
          me.loadLocationPatientRefresh();
        }
      );
    window.Echo.channel(`exam-patient-${me.areaId}`).listen(
      ".exam.patient.created",
      (e) => {
        const exam = JSON.parse(e.data);
        me.fillExam(exam);
      }
    );
    window.Echo.channel(`location-patient-${me.areaId}`)
      .listen(".location.patient.departure.info", (e) => {
        const location = JSON.parse(e.data);
        me.fillAltaInfo(location);
      })
      .listen(".location.patient.created", async (e) => {
        me.loadLocationPatientRefresh();
      })
      .listen(".location.patient.moved", async (e) => {
        const location = JSON.parse(e.data);
        me.loadLocationPatientRefresh();
      })
      .listen(".location.patient.cancel", (e) => {
        const location = JSON.parse(e.data);
        me.fillCancelAltaInfo(location);
      })
      .listen(".location.patient.departure", (e) => {
        const location = JSON.parse(e.data);
        me.fillAlta(location);
      });
    window.Echo.channel('change-turn').listen(
      ".turn.changed",
      (e) => {
        me.loadLocationPatientRefresh();
      }
    );
  },
  async mounted() {
    const me = this;
    if (localStorage.getItem("layout")) {
      const layout = JSON.parse(localStorage.getItem("layout"));
      me.loadDevices(layout);
    }

    me.startMqttClient();
    me.title = me.brand.split("_").join(" ");
    me.nameArea = me.area.split("_").join(" ");
    await me.loadLocationPatient();
  },
};
</script>

<style lang="css">
.widget-body__content {
  padding: 0 !important;
}
</style>
