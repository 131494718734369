<template>
  <b-form @submit.prevent="save" class="mt-3">
    <b-row>
      <b-col lg="12" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="time" class="label_input"> Tiempo en minutos </label>
          <div class="d-flex">
            <b-button
              @click.prevent="minus"
              class="btn_time"
              variant="danger mr-2"
            >
              <b-icon-dash />
            </b-button>
            <b-form-input
              type="number"
              class="w-25 text-center"
              id="time"
              v-model="formData.time"
              disabled
            />
            <b-button
              @click.prevent="plus"
              class="btn_time"
              variant="danger ml-2"
            >
              <b-icon-plus />
            </b-button>
          </div>
        </div>
      </b-col>
      <b-col cols="12" class="d-flex justify-content-center mt-2">
        <b-button :disabled="$v.$invalid" type="submit" variant="success">
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BFormInput,
  BForm,
  BIconPlus,
  BIconDash,
} from "bootstrap-vue";
import { required } from "vuelidate/lib/validators";

export default {
  props: {},
  data() {
    return {
      formData: {
        time: 1,
      },
    };
  },
  components: {
    BCol,
    BRow,
    BButton,
    BFormInput,
    BForm,
    BIconPlus,
    BIconDash,
  },
  validations: {
    formData: {
      time: {
        required,
      },
    },
  },
  methods: {
    save() {
      const me = this;
      me.$emit("save", "2", me.formData);
    },
    minus() {
      const me = this;
      me.formData.time--;
      if (me.formData.time < 1) me.formData.time = 1;
    },
    plus() {
      const me = this;
      me.formData.time++;
      if (me.formData.time > 60) me.formData.time = 60;
    },
  },
};
</script>
