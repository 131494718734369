<template>
  <div>
    <div class="header__10">
      <div class="d-flex justify-content-between align-items-center mb-0">
        <div class="d-flex align-items-center">
          <div class="title_sensor" v-b-tooltip.hover.top="item.sensor">
            {{ item.sensor }} 
          </div>
          <span class="btn-oclock" @click.prevent="() => $emit('loadDataDevices')">
            <b-icon-clock-fill />
          </span>
        </div>
        <div
          v-if="isEdit"
          class="remove"
          :id="index"
          @click.prevent="remove(index)"
        >
          <b-icon-x />
        </div>
      </div>
      <div class="d-flex align-items-center mb-1">
        <b-icon-info-circle-fill
          class="icono_info mr-1"
          v-b-tooltip.hover.top="item.timestamp"
        />
        <div class="subtitle_sensor" v-b-tooltip.hover.top="nameArea">
          {{ nameArea }}
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-around align-items-center body__10">
      <div v-if="item.primarySensor">
        <div class="d-flex justify-content-center flex-column">
          <div
            class="value_sensor mb-0"
            v-for="(value, i) in item.primarySensor.values"
            :key="i"
          >
            <audio preload="auto" :id="'sensor-' + item.id + '-' + value.type">
              <source :src="soundSensor" type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
            <span
              class="d-plex align-items-center"
              :class="loadColor(item.id, value.type, value.value, value.Lmax, value.Lmin)"
              v-if="item.primarySensor.values.length > 2"
            >
              {{ sensorGiroscopio(value) }}
              <b-spinner
                v-if="loadVisible(value.value, value.Lmax, value.Lmin)"
                class="ml-1"
                small
                type="grow"
                label="Spinning"
              ></b-spinner>
            </span>
            <span
              class="d-plex align-items-center"
              :class="loadColor(item.id, value.type, value.value, value.Lmax, value.Lmin)"
              v-else
            >
              {{ value.value + " " + value.unit }}
              <b-spinner
                v-if="loadVisible(value.value, value.Lmax, value.Lmin)"
                class="ml-1"
                small
                type="grow"
                label="Spinning"
              ></b-spinner>
            </span>
          </div>
        </div>
      </div>
      <img class="icono_sensor mb-1" :src="loadImg(item.img)" />
    </div>
    <div class="footer__10">
      <div v-if="item.type == '30'">
        <b-form-input type="range" min="0" max="5" v-model="value2" />
      </div>
      <div v-else>
        <div class="line__" />
        <div class="d-flex justify-content-between align-items-center">
          <div
            @click.prevent="() => $emit('setting', item)"
            class="btn_action"
            v-b-tooltip.hover.top="'Configuración'"
          >
            <img class="img_action1" src="@/assets/images/engranaje.png" />
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div
              class="d-flex mr-2 align-items-center"
              v-if="item.sensorBateria"
            >
              <img
                class="img_indicador"
                :src="imgBateria(item.sensorBateria.values[0].value)"
              />
              <span class="text_bateria">
                {{
                  item.sensorBateria.values[0].value +
                  " " +
                  item.sensorBateria.values[0].unit
                }}
              </span>
            </div>
            <img
              v-if="item.sensorEnergia"
              class="img_indicador"
              :src="imgEnergia(item.sensorEnergia.values[0].value)"
            />
          </div>
          <div class="btn_action" v-b-tooltip.hover.top="'Estadisticas'" @click.prevent="statistics">
            <img
              class="img_action2"
              src="@/assets/images/graficastadistics.png"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  VBTooltip,
  BIconInfoCircleFill,
  BIconX,
  BFormInput,
  BSpinner,
  BIconClockFill,
} from "bootstrap-vue";
import moment from "moment";
moment.locale("es");

export default {
  components: {
    BIconX,
    BIconInfoCircleFill,
    BFormInput,
    BSpinner,
    BIconClockFill,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    nameArea: {
      type: String,
      defaul: null,
    },
    nameEnterprise: {
      type: String,
      defaul: null,
    },
  },
  data() {
    return {
      soundSensor: localStorage.getItem("soundSensor")
        ? JSON.parse(localStorage.getItem("soundSensor")).audio
        : require("@/assets/audio/alerta.mp3"),
    };
  },
  methods: {
    ...mapActions("device", ["removeDevice"]),
    remove(index) {
      // this.$emit("removeDevice", index);
      const me = this;
      me.removeDevice(index);
    },
    loadImg(img) {
      return require(`@/assets/images/devices/${img}`);
    },
    sensorGiroscopio(item) {
      let text = null;

      if (item.type == "ANGULO X") {
        text = `X: ${item.value} ${item.unit}`;
      } else if (item.type == "ANGULO Y") {
        text = `Y: ${item.value} ${item.unit}`;
      } else if (item.type == "FRECUENCIA") {
        text = `f: ${item.value} ${item.unit}`;
      } else {
        text = null;
      }

      return text;
    },
    imgEnergia(value) {
      let img = null;

      if (value == 1) {
        img = require("@/assets/images/devices/energiaencendida.png");
      } else {
        img = require("@/assets/images/devices/energiaapagado.png");
      }

      return img;
    },
    imgBateria(value) {
      let img = null;

      if (value > 15) {
        img = require("@/assets/images/devices/bateriaconcarga.png");
      } else {
        img = require("@/assets/images/devices/bateriasincarga.png");
      }

      return img;
    },
    loadColor(id, type, value, Lmax, Lmin) {
      const me = this;
      let color = "";

      if (value > Lmax) {
        color = "color-text-max";
        me.loadSound(id, type, "1");
      } else if (value < Lmin) {
        color = "color-text-min";
        me.loadSound(id, type, "1");
      } else {
        me.loadSound(id, type, "2");
      }

      return color;
    },
    loadVisible(value, Lmax, Lmin) {
      let visible = false;

      if (value > Lmax) {
        visible = true;
      } else if (value < Lmin) {
        visible = true;
      } else {
        visible = false;
      }

      return visible;
    },
    loadSound(id, typeSensor, type) {
      const me = this;
      const audio = document.getElementById(`sensor-${id}-${typeSensor}`);
      
      if (audio) {
        if (type == "1") {
          audio.load();
          // Show loading animation.
          let playPromise = audio.play();

          if (playPromise !== undefined) {
            playPromise.then(_ => {
              // Automatic playback started!
              // Show playing UI.
            })
            .catch(error => {
              // Auto-play was prevented
              // Show paused UI.
            });
          }
        } else {
          audio.pause();
        }
      }
    },
    statistics() {
      const me = this;
      const today =  (moment(), moment()).format("YYYY-MM-DD HH:mm:ss");
      let a = document.createElement("a");
      const link = `https://siap-graphs.helpmedica.com/report-graphics/${me.item.mac}/null/${me.nameArea}/${me.nameEnterprise}/${today}/${today}`;
      a.href = link;
      a.target = "_brank";
      a.click();
    }
  },
};
</script>

<style lang="css">
.color-text-max {
  color: rgb(194, 19, 19);
}

.color-text-min {
  color: rgb(35, 27, 146);
}
</style>
