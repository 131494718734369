<template>
  <b-form class="mt-3">
    <b-row v-for="(item, index) in sensors" :key="index" class="m-0 p-0">
      <b-col lg="12" md="12" sm="12" class="text-center">
        <div role="group">
          <label for="min" class="label_input"> {{ item.name }} </label>
        </div>
      </b-col>
      <b-row v-for="(sensor, i) in item.values" :key="i">
        <b-col lg="12" md="12" sm="12" class="">
          <div role="group">
            <label for="min" class="label_input"> {{ sensor.type }} </label>
          </div>
        </b-col>
        <b-col lg="6" md="6" sm="6" class="">
          <div role="group">
            <label for="min" class="label_input"> Mínimo </label>
            <div class="d-flex justify-content-center align-items-center">
              <b-button
                @click.prevent="minus(1, item.type, sensor.type)"
                class="btn_time2"
                variant="danger mr-1"
              >
                <b-icon-dash />
              </b-button>
              <b-form-input
                type="number"
                class="w-100 h-50 text-center"
                id="min"
                v-model="sensor.Lmin"
                disabled
              />
              <b-button
                @click.prevent="plus(1, item.type, sensor.type)"
                class="btn_time2"
                variant="danger ml-1"
              >
                <b-icon-plus />
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col lg="6" md="6" sm="6" class="">
          <div role="group">
            <label for="max" class="label_input"> Máximo </label>
            <div class="d-flex justify-content-center align-items-center">
              <b-button
                @click.prevent="minus(2, item.type, sensor.type)"
                class="btn_time2"
                variant="danger mr-1"
              >
                <b-icon-dash />
              </b-button>
              <b-form-input
                type="number"
                class="w-100 h-50 text-center"
                id="max"
                v-model="sensor.Lmax"
                disabled
              />
              <b-button
                @click.prevent="plus(2, item.type, sensor.type)"
                class="btn_time2"
                variant="danger ml-1"
              >
                <b-icon-plus />
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex justify-content-end mt-1">
          <b-button
            size="sm"
            @click.prevent="save(item.type)"
            variant="success"
          >
            Guardar
          </b-button>
        </b-col>
      </b-row>
    </b-row>
  </b-form>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BFormInput,
  BForm,
  BIconPlus,
  BIconDash,
} from "bootstrap-vue";
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    sensors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      formData: {
        min: 0,
        max: 0,
      },
    };
  },
  components: {
    BCol,
    BRow,
    BButton,
    BFormInput,
    BForm,
    BIconPlus,
    BIconDash,
  },
  validations: {
    formData: {
      min: {
        required,
      },
      max: {
        required,
      },
    },
  },
  computed: {
    isValidateCero() {
      const me = this;
      let isValidate = true;

      isValidate = me.formData.min <= 0 || me.formData.max <= 0 ? true : false;

      return isValidate;
    },
  },
  methods: {
    save(type) {
      const me = this;
      me.sensors.forEach(el => {
        if (el.type == type) {
          me.$emit("save", "3", { values: el.values, type: el.type });
        }
      });
    },
    minus(type, type2, type3) {
      const me = this;
      if (type == 1) {
        me.sensors.forEach(el => {
          if (el.type == type2) {
            el.values.forEach(x => {
              if (x.type == type3) {
                x.Lmin--
              }
            });
          }
        });
      } else {
        me.sensors.forEach(el => {
          if (el.type == type2) {
            el.values.forEach(x => {
              if (x.type == type3) {
                x.Lmax--
              }
            });
          }
        });
      }
    },
    plus(type, type2, type3) {
      const me = this;
      if (type == 1) {
        me.sensors.forEach(el => {
          if (el.type == type2) {
            el.values.forEach(x => {
              if (x.type == type3) {
                x.Lmin++
              }
            });
          }
        });
      } else {
        me.sensors.forEach(el => {
          if (el.type == type2) {
            el.values.forEach(x => {
              if (x.type == type3) {
                x.Lmax++
              }
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="css">
.btn_time2 {
  padding: 0;
  height: 25px;
  width: 25px;
  border-radius: 50%;
}
</style>
