<template>
  <b-form @submit.prevent="save" class="mt-3">
    <b-row>
      <b-col lg="12" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="nameDevice" class="label_input"> Nombre </label>
          <b-form-input id="nameDevice" v-model="formData.nameDevice" autofocus />
        </div>
      </b-col>
      <b-col cols="12" class="d-flex justify-content-center mt-2">
        <b-button :disabled="$v.$invalid" type="submit" variant="success">
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { BCol, BRow, BButton, BFormInput, BForm } from "bootstrap-vue";
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    nameDevice: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      formData: {
        nameDevice: this.nameDevice,
      },
    };
  },
  components: {
    BCol,
    BRow,
    BButton,
    BFormInput,
    BForm,
  },
  validations: {
    formData: {
      nameDevice: {
        required,
      },
    },
  },
  methods: {
    save() {
      const me = this;
      me.$emit("save", "1", me.formData);
    },
  },
};
</script>

<style></style>
